import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Validators } from '@bp/shared/features/validation/models';
import { ensureFormGroupConfig } from '@bp/shared/typings';

import { FormBaseComponent } from '@bp/frontend/components/core';
import { takeUntilDestroyed } from '@bp/frontend/models/common';

import { IChangePasswordApiRequest } from '@bp/admins-shared/domains/identity/models';

@Component({
	selector: 'bp-change-password-form',
	templateUrl: './change-password-form.component.html',
	styleUrls: [ './change-password-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class ChangePasswordFormComponent extends FormBaseComponent<IChangePasswordApiRequest> {

	@Input() email?: string;

	override onSubmitShowInvalidInputsToast = false;

	constructor() {
		super();

		this.form = this._formBuilder.group(ensureFormGroupConfig<IChangePasswordApiRequest>({
			currentPassword: [ '', { validators: [ Validators.required ]}],
			newPassword: [ '', { validators: [ Validators.required, Validators.password() ]}],
			confirmNewPassword: [ '', { validators: [ Validators.required, Validators.confirmPassword('newPassword') ]}],
		}));

		this._onNewPasswordChangeRevalidateConfirmNewPassword();
	}

	private _onNewPasswordChangeRevalidateConfirmNewPassword(): void {
		this.controls!.newPassword!.valueChanges
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this.controls!.confirmNewPassword!.updateValueAndValidity());
	}
}
